import React from "react";
import { Helmet } from "react-helmet";
import { useSiteMetadata } from "web-common/src/hooks/useSiteMetadata";

const AdobeLaunch = () => {
  const { analyticsAdobeLaunchScriptName, oneTrustDataDomainScriptId } = useSiteMetadata();
  const adobeLaunchScript = `https://assets.adobedtm.com/${analyticsAdobeLaunchScriptName}`;
  return (
    <>
      <Helmet>
        <script
          async
          src={adobeLaunchScript}
          data-document-language="true"
          type={oneTrustDataDomainScriptId?"text/plain":"text/javascript"}
          charSet="UTF-8"
          className={oneTrustDataDomainScriptId?"optanon-category-C0002-C0004": null }
        />
      </Helmet>
    </>
  );
};

export default AdobeLaunch;